<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }" >
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Operation') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('CUS Order Update') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <!-- content -->
    <validation-observer ref="baseInfoRules">
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Base Info') }}</b-card-title>
      </b-card-header>
      <b-card-body class="ll-cbody">
        <b-row>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="orderNo"
            >
              <validation-provider
                #default="{ errors }"
                name="Order No"
                rules="required"
              >
                <label class="ll-boldText">{{ $t('Order No') }}</label>
                <b-form-input
                  v-model="orderNo"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Type')"
              label-for="s"
            >
              <v-select
                v-if="!isCan"
                :options="typeList"
                label="codeDescription"
                @input="selType"
                v-model="type"
              ></v-select>
              <b-form-input
                v-if="isCan"
                :disabled="isCan"
              />
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Date')"
              label-for="s"
            >
              <!-- <flat-pickr
                v-model="baseInfo.inboundTime"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
                :disabled="!isCan"
                v-if="!isCan"
              /> -->
              <b-form-input
                v-model="baseInfo.inboundTime"
                :disabled="isCan1"
                class="cursor-not-allowed"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Account No')+'.'"
              label-for="s"
            >
              <b-form-input
                :disabled="isCan1"
                v-model="baseInfo.accountNo"
                class="cursor-not-allowed"
              />
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Pieces')"
              label-for="s"
            >
              <b-form-input
                :disabled="isCan"
                v-model="baseInfo.updPieces"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Weight')"
              label-for="s"
            >
              <b-form-input
                :disabled="isCan"
                v-model="baseInfo.updWeight"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Volumn Weight')"
              label-for="s"
            >
              <b-form-input
                :disabled="isCan"
                v-model="info.updVolumn"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col md='4' v-if="false">
            <b-form-group
              class="ll-bform"
              :label="$t('Content')"
              label-for="s"
            >
              <v-select
                v-if="!isCan"
                :options="contentList"
                label="codeDescription"
                @input="selContent"
                v-model="content"
              ></v-select>
              <b-form-input
                v-if="isCan"
                :disabled="isCan"
              />
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Paid By')"
              label-for="s"
            >
              <v-select
                v-if="!isCan"
                :options="paidByList"
                label="codeDescription"
                @input="selPaidBy"
                v-model="paidBy"
              ></v-select>
              <b-form-input
                v-if="isCan"
                :disabled="isCan"
              />
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="s"
            >
              <validation-provider
                #default="{ errors }"
                name="Supplier"
                rules="required"
              >
                <label class="ll-boldText">{{ $t('Supplier') }}</label>
                <v-select
                  v-if="!isCan"
                  :options="supplierList"
                  label="codeDescription"
                  @input="selSupplier"
                  v-model="supplier"
                ></v-select>
                <b-form-input
                  v-if="isCan"
                  :disabled="isCan"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="Location"
            >
              <validation-provider
                #default="{ errors }"
                name="Location"
                rules="required"
              >
                <label class="ll-boldText">{{ $t('Location') }}</label>
                <v-select
                  v-if="!isCan"
                  :options="locationList"
                  label="codeDescription"
                  @input="selLocation"
                  v-model="location"
                ></v-select>
                <b-form-input
                  v-if="isCan"
                  :disabled="isCan"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Charges')"
              label-for="s"
            >
              <b-form-input
                :disabled="isCan1"
                v-model="info.charges"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Remarks')"
              label-for="s"
            >
              <b-form-input
                :disabled="isCan"
                v-model="info.updRemark"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Package Description')"
              label-for="s"
            >
              <b-form-input
                :disabled="true"
                v-model="info.pkDesc"
              />
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-button variant="primary" @click="onItemDerail"
              class="ll-itemBtn ll-btns"
              :disabled="isCan"
            >
              {{ $t('Item detail') }}
            </b-button>
          </b-col>
        </b-row>
        <vxe-table
          v-if="isDetails"
          border
          show-overflow
          keep-source
          ref="xTable"
          max-height="400"
          :data="items"
          :edit-config="{trigger: 'click', mode: 'row', showStatus: true}"
          empty-text="No matching records found"
        >
          <vxe-table-column field="itCusDesc" title="Item Description">
            <template #default="{ row }">
              <!-- <b-form-input v-model="row.itCusDesc" /> -->
              <b-form-select
                v-model="row.itCusDesc"
                :options="contentList"
                value-field="codeName"
                text-field="codeDescription"
              >
              </b-form-select>
            </template>
          </vxe-table-column>
          <vxe-table-column field="itWeight" title="Weight(KG)"  >
            <template #default="{ row }">
              <b-form-input v-model="row.itWeight" type="number"/>
            </template>
          </vxe-table-column>
          <vxe-table-column field="itUnitPrice" title="Unit price">
            <template #default="{ row }">
              <b-form-input v-model="row.itUnitPrice" type="number"/>
            </template>
          </vxe-table-column>
          <vxe-table-column field="itCurrency" title="Currency" >
            <template #default="{ row }">
              <b-form-select
                v-model="row.itCurrency"
                :options="valueUnitList"
              >
              </b-form-select>
              <!-- <b-form-input v-model="row.itCurrency" /> -->
            </template>
          </vxe-table-column>
          <!-- <vxe-table-column title="Actives" width="100" show-overflow>
            <template #default="{ row }" >
              <vxe-button type="text" icon="" @click="insertEvent()">
                <feather-icon icon="PlusIcon" />
              </vxe-button>
              <vxe-button type="text" icon="" @click="removeEvent(row)">
                <feather-icon icon="Trash2Icon" />
              </vxe-button>
            </template>
          </vxe-table-column> -->
        </vxe-table>
        <div class="ll-addbtn" v-if="isDetails">
          <b-button
            variant="primary"
            @click="insertEvent()"
          >
            <span>+ {{ $t('Add') }} {{ $t('Item') }}</span>
          </b-button>
        </div>
      </b-card-body>
    </b-card>
    </validation-observer>
    <div class="ll-subBtn">
      <b-button
        variant="info"
        @click.prevent="validationForm"
      >
        {{ $t('Save') }}
      </b-button>
    </div>
    <div class="ll-returnInfo" :style="{'color': !isfail ? '#409eff' : '#ea5455'}">
      {{returnInfo}}
    </div>
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem,
  BRow, BCol, BFormGroup, BFormInput, BButton,
  BCard, BCardBody, BCardHeader, BCardTitle, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { debounce } from "@/libs/fun.js"

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    BButton,
    BFormSelect,
  },
  data() {
    return {
      required,
      orderNo: null,
      baseInfo: {
        inboundTime: null,
        accountNo: null,
        updPieces: null,
        updWeight: null,
      },
      isCan: true,
      isCan1: true,
      info: {
        customerOrderNo: null,
        customerType: null,
        updPieces: null,
        updWeight: null,
        charges: null,
        supplier: null,
        location: null,
        updVolumn: null,
        paidBy: null,
        content: null,
        updRemark: null,
        pkDesc: null,
      },
      typeList: [],
      type: null,
      contentList: [],
      content: null,
      paidByList: [],
      paidBy: null,
      locationList: [],
      location: null,
      supplierList: [],
      supplier: null,
      isDetails: false,
      items: [
        { itCusDesc: null, itWeight: null, itUnitPrice: null, itCurrency: null}
      ],
      tkID: '',
      valueUnitList: ['HKD', 'CNY', 'USD', 'EUR'],
      returnInfo: null,
      isfail: null,
    }
  },
  computed: {},
  watch: {
    orderNo(val) {
      if (val !== null && val !== '') {
        this.isCan = false
        this.getOrderInfo(val)
      } else {
        this.isCan = true
      }
    }
  },
  created() {},
  mounted() {
    this.queryType()
    this.queryContent()
    this.queryPaidBy()
    this.queryLocation()
    this.querySupplier()
  },
  methods: {
    getOrderInfo: debounce(function(oNo) {
      this.$http.get(`/jobkes/getOrderInfo?customerOrderNo=${encodeURIComponent(oNo)}`).then(res => {
        const info = res.data.data
        this.baseInfo = info
        this.baseInfo.updWeight = (info.updWeight!==null) ? info.updWeight : info.actWeight
        this.baseInfo.updPieces = (info.updPieces!==null) ? info.updPieces : info.itemQty
        this.type = info.customerType
        this.info.customerType = info.customerType
        this.content = info.content
        this.info.content = info.content
        this.paidBy = info.paidBy
        this.info.paidBy = info.paidBy
        this.location = info.location
        this.info.location = info.location
        this.supplier = info.supplier
        this.info.supplier = info.supplier
        this.info.updVolumn = info.updVolumn
        this.info.charges = info.charges
        this.tkID = info.tkID
        this.items = info.orderDetailList
        this.info.updRemark = info.updRemark
        this.info.pkDesc = info.pkDesc
        if (info.orderDetailList.length>0) {
          this.isDetails = true
        } else{
          this.isDetails = false
        }
      })
    }, 100),
    submit: debounce(function() {
      this.info.customerOrderNo = this.orderNo
      this.info.updPieces = this.baseInfo.updPieces
      this.info.updWeight = this.baseInfo.updWeight
      const $table = this.$refs.xTable
      if ($table !== undefined) {
        const insertRecords = $table.getInsertRecords() // 获取新增
        const updateRecords = $table.getUpdateRecords() // 获取修改
        // 只有新增
        if (insertRecords.length !== 0 && updateRecords.length === 0) {
          const arr = []
          insertRecords.map(i =>{
            const obj = {}
            obj.itCusDesc = i.itCusDesc
            obj.itWeight = i.itWeight
            obj.itWeightUOM = 'KG'
            obj.itUnitPrice = i.itUnitPrice
            obj.itCurrency = i.itCurrency
            obj.tkID = this.tkID
            arr.push(obj)
          })
          const arr1 = $table.tableData.filter(x => !insertRecords.includes(x))
          this.info.orderDetailList = arr.concat(arr1)
        // 只有修改
        } else if (insertRecords.length === 0 && updateRecords.length !== 0) {
          this.info.orderDetailList = this.items
        // 既有新增又有修改
        } else if (insertRecords.length !== 0 && updateRecords.length !== 0) {
          const arr = []
          insertRecords.map(i =>{
            const obj = {}
            obj.itCusDesc = i.itCusDesc
            obj.itWeight = i.itWeight
            obj.itWeightUOM = 'KG'
            obj.itUnitPrice = i.itUnitPrice
            obj.itCurrency = i.itCurrency
            obj.tkID = this.tkID
            arr.push(obj)
          })
          const arr2 = arr.concat(this.items)
          this.info.orderDetailList = arr2
        } else {
          // 无操作
          this.info.orderDetailList = []
        }
      } else {

      }
      this.$http.post('/jobkes/updateOrderInfo', this.info).then(res=>{
        if (res.data.code === '200') {
          this.isfail = false
          this.returnInfo = `${this.orderNo} Success`
          this.orderNo = null
          this.baseInfo.accountNo = null
          this.items = []
          this.info.pkDesc = null
        } else {
          this.isfail = ture
          this.returnInfo = `${this.orderNo} Fail`
        }
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: res.data.message,
        //     icon: 'CheckIcon',
        //     variant: 'success',
        //   },
        // })
      })
    }, 100),
    validationForm() {
      this.$refs.baseInfoRules.validate().then(success => {
        if (success) {
          //  校验成功
          this.submit()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
    queryType() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'CUSType'}}).then(res => {
        this.typeList = res.data.data
      })
    },
    selType(d) {
      this.info.customerType = d ? d.codeName : null
      this.type = d ? d.codeDescription : null
    },
    queryContent() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'CUSContent'}}).then(res => {
        this.contentList = res.data.data
      })
    },
    selContent(d) {
      // this.info.content = d.codeName
      // this.content = d.codeDescription
    },
    queryPaidBy() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'CUSPaidBy'}}).then(res => {
        this.paidByList = res.data.data
      })
    },
    selPaidBy(d) {
      this.info.paidBy = d ? d.codeName : null
      this.paidBy = d ? d.codeDescription : null
    },
    queryLocation() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'CUSLocation'}}).then(res => {
        this.locationList = res.data.data
      })
    },
    selLocation(d) {
      this.info.location = d ? d.codeName : null
      this.location = d ? d.codeDescription : null
    },
    querySupplier() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'CUSSupplier'}}).then(res => {
        this.supplierList = res.data.data
      })
    },
    selSupplier(d) {
      this.info.supplier = d ? d.codeName : null
      this.supplier = d ? d.codeDescription : null
    },
    onItemDerail() {
      this.isDetails = !this.isDetails
    },
    async insertEvent (row) {
      const $table = this.$refs.xTable
      const record = {}
      const { row: newRow } = await $table.insertAt(record, row)
      await $table.setActiveCell(newRow)
    },
    removeEvent (row) {
      this.box = ''
      this.$bvModal
      .msgBoxConfirm('Please confirm that you want to delete .', {
        title: 'Handle',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(value => {
        if (value) {
          this.box = value
          this.$refs.xTable.remove(row)
        }
      })
    },
  }
}
</script>

<style scoped>
.ll-returnInfo{
  text-align: center;
  font-size: 40px;
}
.ll-subBtn{
  text-align: right;
  height: 50px;
}
.ll-btns{
  margin-top: 18px;
}
.ll-addbtn{
  text-align: center;
  margin-top: 1rem;
}
</style>